import React from 'react';
import UsefulInfo from "../footer/usefulInfo";
import Location from "../footer/location";
import "../information.css"
import HeaderBigBlack from '../header/headerBigBlack';
import HeaderBlack from '../header/headerBlack';
import { Link } from "react-router-dom";
import { useLanguage } from '../LanguageContext';

function Information() {

    let [language, languageTexts, languageKatilai, toggleLanguage] = useLanguage();
    const translation = languageTexts;

  return <>

        <HeaderBigBlack activeTab={"Informacija"}/>
        <div className='smallInfoHeader'><HeaderBlack activeTab={"Informacija"}/></div>

    <div className='informationBody'>
        {/* <nav className="Nav" style={{position: "static"}}>
                        
                        <p>
                            <span><Link to={"/informacija"}>{translation[6].text}</Link></span>
                        </p>
        </nav> */}
        <div className='informationTitle'>
            <h1>{translation[51].text}</h1>
        </div>
        <div className='information1'>
            <div className='pajungimas'>
                <div className='pajungimasIcon'><img src='../assets/pajungimas.svg' alt='pajungimas_logo'></img></div>
                <h1>{translation[52].text}</h1>
                <p>{translation[53].text}</p>
            </div>
            <hr/>
            <div className='paleidimas'>
                <div className='paleidimasIcon'><img src='../assets/paleidimas.svg' alt='paleidimas_logo'></img></div>
                <div className='paleidimasIconJuodas'><img src='../assets/paleidimas_juodas.svg'alt='paleidimas_logo_juodas'></img></div>
                <h1>{translation[54].text}</h1>
                <p>{translation[55].text}</p>
                <hr/>
                <Link to='/specialistai' className='WhiteLink' id='mobileBlack'>{translation[56].text} &gt;</Link>
            </div>
        </div>
        <hr/>
        <div className='information2'>
            <div className='randomInfoImages'>
                <img className='biggerRandomInfoImage' src='../assets/landing_01.png' alt='ph1'></img>
                {/*<img className='smallerRandomInfoImage' src='../assets/placeholder2.jpg' alt='ph2'></img>*/}
            </div>
            <div className='garantija'>
                <div className='greyInfoLogo'><img className='greyInfoLogoImage' src='../assets/grey_logo.png' alt="grey_logo"></img></div>
                <div className='garantijaText'>
                    <div className='garantijaIcon'><img src='../assets/garantija.svg' alt='garantija_logo'></img></div>
                    <h1>{translation[57].text}</h1>
                    <p>{translation[58].text}</p>
                </div>
            </div>
        </div>
    </div>
    <footer>
        <div className="infoFooter"><UsefulInfo/></div>
        <div className="locationFooter"><Location/></div>
    </footer>
  </>;
}

export default Information;