import "./App.css"
import { Katilas } from "./katilai/Katilas";
import KatilasList from "./katilai/KatilasList";
import { MOCK_KATILAI } from "./katilai/MockKatilai";
import { MOCK_KATILAI_EN } from './katilai/MockKatilaiEn';
import UsefulInfo from "./footer/usefulInfo";
import Location from "./footer/location";
import Header from "./header/header";
import HeaderBlack from "./header/headerBlack";
import { useState, useEffect, useRef } from "react";
import React from "react";
import { useLanguage } from './LanguageContext';
import LoadingPage from './loadingPage';
import Advert from "./treeInfo/advert";


function Home(props){
    const { activeKatilai } = props
    const [items, setItems] = useState([]);
    let [language, languageTexts, languageKatilai]= useLanguage();
    const translation = languageTexts;
    const [viableScroll, setViableScroll] = useState();
    

   
    useEffect(() => {
        window.addEventListener('load', (event) => {
            //setIsLoaded(true);
            document.getElementById("loadingDivId").classList.add('fadeForLoading');
          });
          if (document.readyState === 'complete') document.getElementById("loadingDivId").classList.add('fadeForLoading');
    }, [items]);

    useEffect(() => {
        setItems(MOCK_KATILAI);
    }, []);

    useEffect(() => {
        const onPageLoad = () => {
            if (activeKatilai !== "") {
                window.scrollTo({
                    top: document.getElementById("katiluScroll").offsetTop,
                    behavior: "smooth",
                });
            }
        };

        if (document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad, false);
            return () => window.removeEventListener('load', onPageLoad);
        }
    }, []);

    return(
        <>
            <header>
                
              <Advert></Advert>
                <div className="mainPageHeader">
                    <Header katilai={language === "lt" ? items : languageKatilai} activeCategory={activeKatilai}/>
                </div>
                <div className="smallHeader"><HeaderBlack activeTab={activeKatilai}/></div>
            </header>
            <section id="katiluScroll"  className='katilai'>
                {activeKatilai === "Granulinio kuro" || activeKatilai === "" ?
                    <>
                        <h1 className="main-katilai">{translation[2].text} {translation[0].text.toLowerCase()}</h1>
                        <KatilasList katilai={language === "lt" ? items : languageKatilai} activeKatilai={"Granulinio kuro"}></KatilasList>  
                    </> 
                :null}
                {activeKatilai === "Kieto kuro" || activeKatilai === "" ?
                    <>
                        <h1 className="main-katilai">{translation[3].text} {translation[0].text.toLowerCase()}</h1>
                        <KatilasList katilai={language === "lt" ? items : languageKatilai} activeKatilai={"Kieto kuro"}></KatilasList>
                    </> 
                :null}
                {activeKatilai === "Žemės ūkio atliekų"  || activeKatilai === "" ?
                    <>
                        <h1 className="main-katilai">{translation[4].text} {translation[0].text.toLowerCase()}</h1>
                        <KatilasList katilai={language === "lt" ? items : languageKatilai} activeKatilai={"Žemės ūkio atliekų"}></KatilasList>
                    </> 
                :null}
                {activeKatilai === "Pramoniniai" || activeKatilai === "" ?
                    <>
                        <h1 className="main-katilai">{translation[5].text} {translation[0].text.toLowerCase()}</h1>
                        <KatilasList katilai={language === "lt" ? items : languageKatilai} activeKatilai={"Pramoniniai"}></KatilasList>
                    </> 
                :null}
            </section>
            <footer>
                <div className="infoFooter"><UsefulInfo/></div>
                <div className="locationFooter"><Location/></div>
            </footer>
            <LoadingPage/>
        </>
    );
}

export default Home